<template>
    <div class="page">
        <div class="content">
            <div class="page-title flex justify-between bg-white">
                <h1 class="typography-h1 title bg-white">
                    Slevy
                </h1>
                <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
            </div>

            <div class="wrapper">
                <div class="row">
                    <div class="col-4 q-pr-md discounts-wrapper">
                        <q-btn
                            v-if="!createMode|| active || viewMode"
                            @click="showCreateCard"
                            class="my-btn create-card"
                            style="width: 100%"
                            label="Přidat slevu"
                            type="text"
                            color="black"
                            icon-right="svguse:/icons.svg#plus2px"
                        />
                        <CardLocalDiscount class="skeleton" @remove="createMode=false" v-if="createMode && !active"/>
                        <div class="q-mb-md"></div>
                        <div
                             v-if="filteredDiscounts.length"
                             v-for="discount in filteredDiscounts"
                             :key="discount.id"
                             class="q-mb-md">
                            <CardLocalDiscount
                                :active="active && (active.id === discount.id)"
                                @click.native="setActive(discount)"
                                :content="{
                                    id:discount.id,
                                    percentage:(1-discount.multiplier)*100,
                                    name:discount.name.cs,

                                 }"
                                @remove="del(discount)"/>
                        </div>
                    </div>
                    <div class="col-8">
                        <div v-if="!filteredDiscounts.length && !createMode && !viewMode" class="row justify-center items-center"
                             style="height: 188px; width: 100%; border: 1px solid #BABABA">
                            <q-icon name="svguse:/icons.svg#drag-drop" size="48px"/>
                        </div>
                        <div v-if="filteredDiscounts.length && !createMode && !viewMode" class="empty">
                            <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                            <span class="text-grey-6">{{$t('label.select-discount')}}</span>
                        </div>
                        <CardDiscountCreate @close="closeEdit" @create="createDiscount" v-if="createMode && !viewMode && !active"/>
                        <CardDiscountCreate @close="closeEdit" :content="active" @create="updateDiscount" v-if="viewMode && active"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardLocalDiscount from "../components/Card/CardLocalDiscount/CardLocalDiscount";
import CardDiscountCreate from "../components/Card/CardDiscountCreate/CardDiscountCreate";

export default {
    components: {CardDiscountCreate, CardLocalDiscount},
    meta:{
        title: 'Slevy',
    },
    data: () => ({
        createMode: false,
        active: null,
        viewMode: false,
        listArchived: false,
    }),
    computed:{
        filteredDiscounts() {
            return (this.globalDiscounts || []).filter(g => this.listArchived ? g.archived : !g.archived).reverse();
        },
    },
    methods: {
        showCreateCard(){
            this.active = null
            this.createMode = false
            this.viewMode = false;
            this.active = null;
            setTimeout(()=>{
                this.createMode = true
            }, 0)
        },
        setActive(item) {
            this.createMode = false
            this.viewMode = true;
            this.active = null
            setTimeout(()=>{
                this.active = item
                this.viewMode = true
            }, 0)

        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
        },
        async updateDiscount(item){
            const transformed = {
                ...item.original,
                replacementPrice: null,
                label: null,
                name: {
                    cs: item.czTitle,
                    en: item.enTitle
                },
                multiplier: 1 - item.discount / 100,
                isGlobal: true
            }
            const discount = await this.update('globalDiscounts', transformed);
            notifyOk(this.$t('notification.general_information-updated'));
            this.updateIn(this.globalDiscounts, discount);
            this.active=discount
        },
        async createDiscount(item) {
            const transformed = {
                replacementPrice: null,
                label: null,
                name: {
                    cs: item.czTitle,
                    en: item.enTitle
                },
                multiplier: 1 - item.discount / 100,
                isGlobal: true
            }
            const discount = await this.create('globalDiscounts', transformed);
            this.globalDiscounts.push(discount);
            notifyOk(this.$t('notification.discount_saved'));
            this.active=discount
            this.createMode = false
            this.viewMode = false;
            this.viewMode = true;
        },
        async del(discount) {
            const {result, archived} = await this.delete('globalDiscounts', discount);
            if (result === 'destroyed') {
                notifyOk(this.$t('notification.discount_deleted'));
                this.deleteIn(this.globalDiscounts, discount);
                this.createMode = false
                this.closeEdit()
            } else if (result === 'archived') {
                notifyOk("Sleva byla archivována.");
                discount.archived = archived;
                this.updateIn(this.globalDiscounts, discount);
                this.closeEdit()
            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }
        },
    },

    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        await this.fetchMany('globalDiscounts');
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    }
};
</script>
<style lang="scss" scoped>
.page{
    overflow: hidden;
    height: 100vh;

}
.wrapper{
    overflow: hidden;
    position: relative;
    padding: 32px 16px 0;
}
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.discounts-wrapper {
    height: calc(100vh -  121px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.create-card, .skeleton{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
.empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
    width: 100%;
    border: 1px solid #BABABA;

}
</style>
